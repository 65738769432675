<script setup>
import { useLinkModelView } from "@vueda/use/useLinkModelView.js";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { computed } from "vue";
import { useRouter } from "vue-router";

import FontAwesomeIconDisplay from "@/components/FontAwesomeIconDisplay.vue";
import { useIconMap } from "@/use/useIconMap.js";

const props = defineProps({
    icon: {
        type: [String, Array],
        default: null,
    },
    verb: {
        type: String,
        default: undefined,
    },
    title: {
        type: String,
        default: undefined,
    },
    app: {
        type: String,
        default: undefined,
    },
    model: {
        type: String,
        default: undefined,
    },
    pk: {
        type: [String, Number, Array],
        default: undefined,
    },
    pkRequired: {
        type: Boolean,
        default: false,
    },
    view: {
        type: [String, Array, Object],
        default: undefined,
    },
    to: {
        type: [String, Object],
        default: undefined,
    },
    onClick: {
        type: Function,
        default: undefined,
    },
    href: {
        type: String,
        default: undefined,
    },
    variant: {
        type: String,
        default: "link",
        validator: (value) => ["text", "outlined", "link", null].includes(value),
    },
    skipModelLookup: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});
const toast = useToast();
const router = useRouter();
const autoIcon = useIconMap(props);
const linkModelView =
    props.skipModelLookup ?
        {
            href: computed(() => props.href),
            actionDisabled: computed(() => props.disabled),
            navigate: () => {},
        }
    :   useLinkModelView(props);
const handleClick = (e) => {
    if (props.pkRequired && !props.pk.length) {
        e.preventDefault();
        toast.add({
            severity: "info",
            summary: "Nothing selected",
            detail: `Please select a ${props.model} before proceeding.`,
            life: 10000,
        });
    }
    if (props.href) {
        return;
    }
    if (props.onClick) {
        e.preventDefault();
        props.onClick();
    } else if (props.to) {
        e.preventDefault();
        router.push(props.to);
    } else if (linkModelView.href.value && !linkModelView.actionDisabled.value) {
        e.preventDefault();
        linkModelView.navigate();
    }
    // let the click event propagate
};
const toHref = computed(() => {
    if (props.to) {
        return typeof props.to === "string" ? props.to : router.resolve(props.to).href;
    }
    return undefined;
});
const combinedHref = computed(() => props.href || toHref.value || linkModelView.href.value);
</script>

<template>
    <Button v-tooltip="title" @click="handleClick" as="a" :href="combinedHref" :variant="variant">
        <!-- primevue passes nothing via their button default slot... -->
        <!-- when using the default slot, they don't render the icon slot... -->
        <template #default>
            <slot />
        </template>
        <template v-if="autoIcon" #icon="slotProps">
            <span :class="slotProps.class">
                <font-awesome-icon-display :icon="autoIcon" />
            </span>
        </template>
    </Button>
</template>
