import { config } from "@arrai-innovations/fontawesome-svg-core";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import { setPrimeVuePreset } from "@vueda/theme/register.js";
import { setUsingVuedaWorkFlow } from "@vueda/use/useWorkflow.js";
import { setupDefaultListCrud } from "@vueda/utils/listCrud.js";
import { setupDefaultObjectCrud } from "@vueda/utils/objectCrud.js";
import { createPinia } from "pinia";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import { createApp } from "vue";

import TheApp from "@/TheApp.vue";
import { getRouter } from "@/router";
import { setupModelConfig } from "@/setupModelConfig.js";
import CustomizedLara from "@/setupPrimeVueTheme.js";
import { setupVuedaTheme } from "@/setupVuedaTheme.js";

config.autoAddCss = false;

setupDefaultListCrud();
setupDefaultObjectCrud();

const pinia = createPinia();
const app = createApp(TheApp);
app.use(pinia);
app.use(PrimeVue, {
    theme: {
        preset: CustomizedLara,
        options: {
            darkModeSelector: ".dark",
        },
    },
    cssLayer: {
        name: "primevue",
        order: "tailwind-base, primevue, custom-theme, tailwind-utilities",
    },
    ripple: true,
    pt: {
        toast: {
            root: {
                class: {
                    "max-w-[calc(-40px_+_100vw)]": true,
                },
            },
            // message is also a component, but this refers to the toast's message, not globally
            message: ({ props }) => ({
                class: {
                    "p-toast-message-primary": props?.message?.severity === "primary",
                },
            }),
        },
    },
    locale: {
        firstDayOfWeek: 1,
        dateFormat: "yy-mm-dd",
    },
});
setPrimeVuePreset(CustomizedLara);
app.use(ToastService);
app.directive("tooltip", Tooltip);
const router = getRouter(app, pinia);
app.use(router);
// no thanks, has issues in production that I'm not interested in debugging
app.component("RouterLink", undefined);
if (!import.meta.env.DEV) {
    // sentry interferes with error handling in setup functions, losing the stack trace
    // todo: some of these integrations are deprecated, need to update
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: ["localhost", "arrai.com", "vueda.dev", /^\//],
            }),
            new Sentry.Replay({
                // Additional SDK configuration goes in here, for example:
                maskAllText: false,
                maskAllInputs: false,
            }),
        ],
        // Capture 100% of traces until we are more stable
        tracesSampleRate: 1.0,
        trackComponents: true,
        // Capture 100% of replays until we are more stable
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
    });
}
app.mount("#the-app");
setupModelConfig(pinia);
setupVuedaTheme();
setUsingVuedaWorkFlow(false);
export default app;
