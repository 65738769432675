import typography from "@tailwindcss/typography";
import current from "tailwindcss-current";
import tailwindCSSPrimeUI from "tailwindcss-primeui";
import plugin from "tailwindcss/plugin.js";

export default {
    darkMode: "class",
    content: [
        "./index.html",
        "./src/**/*.{vue,js,ts,jsx,tsx}",
        "./setupVuedaTheme.js",
        "./node_modules/@primevue/themes/lara/**/*.{vue,js,ts,jsx,tsx}",
        "./node_modules/@arrai-innovations/vueda/lib/**/*.{vue,js,ts,jsx,tsx}",
    ],
    theme: {
        extend: {
            borderRadius: {
                // this is in the docs but not in the code
                sm: "0.125rem",
            },
            screens: {
                "2xs": "480px",
                "3xl": "1920px",
                "4xl": "2240px",
                "5xl": "2560px",
                inf: "999999px",
            },
            animation: {
                // more reasonable spin speed.
                spin: "spin 2s linear infinite",
            },
            width: {
                fit: "fit-content",
            },
            minWidth: {
                "2/3": "66.6%",
            },
            colors: {
                "material-black": "#121212",
                zinc: {
                    0: "#fefefe",
                },
                // https://uicolors.app/create
                "azure-radiance": {
                    50: "#edfaff",
                    100: "#d6f2ff",
                    200: "#b5ebff",
                    300: "#83e0ff",
                    400: "#48ccff",
                    500: "#1eadff",
                    600: "#0690ff",
                    700: "#0078f7", // arrai-blue
                    800: "#085ec5",
                    900: "#0d529b",
                    950: "#0e325d",
                },
                christi: {
                    50: "#f3faeb",
                    100: "#e5f3d4",
                    200: "#cbe9ad",
                    300: "#aad97d",
                    400: "#8bc754",
                    500: "#6cab36", // shelby-green
                    600: "#528828",
                    700: "#406922",
                    800: "#365420",
                    900: "#2f481f",
                    950: "#16270c",
                },
                "fuchsia-blue": {
                    50: "#faf7fd",
                    100: "#f3ecfb",
                    200: "#e9ddf7",
                    300: "#d9c1f1",
                    400: "#c19ae6",
                    500: "#a872da",
                    600: "#9354c9", // compliment to shelby-green
                    700: "#7e41af",
                    800: "#6a3990",
                    900: "#572f74",
                    950: "#3a1853",
                },
                maroon: {
                    50: "#ffefef",
                    100: "#ffdbdb",
                    200: "#ffbebe",
                    300: "#ff9090",
                    400: "#ff5151",
                    500: "#ff1b1b",
                    600: "#ff0000",
                    700: "#de0000",
                    800: "#b70000",
                    900: "#850505",
                    950: "#530000",
                },
                crimson: {
                    50: "#fff0f2",
                    100: "#ffe3e4",
                    200: "#ffcbd1",
                    300: "#ffa0aa",
                    400: "#ff6b7e",
                    500: "#fb3855",
                    600: "#dc143c",
                    700: "#c50b34",
                    800: "#a50c33",
                    900: "#8d0e33",
                    950: "#4f0217",
                },
                "international-orange": {
                    50: "#fff7ec",
                    100: "#ffeed3",
                    200: "#ffd9a7",
                    300: "#ffbd6e",
                    400: "#ff9634",
                    500: "#ff760d",
                    600: "#f75c03",
                    700: "#c94205",
                    800: "#a0340c",
                    900: "#802e0e",
                    950: "#451405",
                },
            },
        },
    },
    plugins: [
        tailwindCSSPrimeUI,
        current(".current"),
        plugin(function ({ addUtilities, addVariant }) {
            addUtilities({
                ".subgrid": {
                    "grid-template-columns": "subgrid",
                    "grid-template-rows": "subgrid",
                },
            });
            addVariant("subgrid", "@supports (grid-template-columns: subgrid) and (grid-template-rows:subgrid)");
            addVariant("no-subgrid", "@supports (not (grid-template-columns: subgrid))");
        }),
        // override disabled, enabled to use .disabled class as well
        plugin(function ({ addVariant, e }) {
            addVariant("disabled", ({ modifySelectors, separator }) => {
                modifySelectors(({ className }) => {
                    return `.${e(`disabled${separator}${className}`)}:is(.disabled,:disabled)`;
                });
            });
            addVariant("enabled", ({ modifySelectors, separator }) => {
                modifySelectors(({ className }) => {
                    return `.${e(`enabled${separator}${className}`)}:is(:not(.disabled,:disabled))`;
                });
            });
        }),
        // disabled-within variant
        // https://github.com/tailwindlabs/tailwindcss/discussions/10590
        plugin(function ({ addVariant }) {
            addVariant(
                "disabled-within",
                `&:has(a:is(:disabled), input:is(:disabled), button:is(:disabled), select:is(:disabled), textarea:is(:disabled), *:is(.disabled))`,
            );
            addVariant(
                "enabled-within",
                `&:not(:has(a:is(:disabled))):not(:has(input:is(:disabled))):not(:has(button:is(:disabled))):not(:has(select:is(:disabled))):not(:has(textarea:is(:disabled))):not(:has(*:is(.disabled)))`,
            );
            addVariant(
                "group-disabled-within",
                `:merge(.group):has(a:is(:disabled), input:is(:disabled), button:is(:disabled), select:is(:disabled), textarea:is(:disabled), *:is(.disabled)) &`,
            );
            addVariant(
                "group-enabled-within",
                `:merge(.group):not(:has(a:is(:disabled))):not(:has(input:is(:disabled))):not(:has(button:is(:disabled))):not(:has(select:is(:disabled))):not(:has(textarea:is(:disabled))):not(:has(*:is(.disabled))) &`,
            );
            addVariant(
                "peer-disabled-within",
                `:merge(.peer):has(a:is(:disabled), input:is(:disabled), button:is(:disabled), select:is(:disabled), textarea:is(:disabled), *:is(.disabled)) ~ &`,
            );
            addVariant(
                "peer-enabled-within",
                `:merge(.peer):not(:has(a:is(:disabled))):not(:has(input:is(:disabled))):not(:has(button:is(:disabled))):not(:has(select:is(:disabled))):not(:has(textarea:is(:disabled))):not(:has(*:is(.disabled))) ~ &`,
            );
        }),
        // active-within variant
        plugin(function ({ addVariant }) {
            addVariant(
                "active-within",
                `&:has(a:is(:active), input:is(:active), button:is(:active), select:is(:active), textarea:is(:active), *:is(.active))`,
            );
            addVariant(
                "inactive-within",
                `&:not(:has(a:is(:active))):not(:has(input:is(:active))):not(:has(button:is(:active))):not(:has(select:is(:active))):not(:has(textarea:is(:active))):not(:has(*:is(.active)))`,
            );
            addVariant(
                "group-active-within",
                `:merge(.group):has(a:is(:active), input:is(:active), button:is(:active), select:is(:active), textarea:is(:active), *:is(.active)) &`,
            );
            addVariant(
                "group-inactive-within",
                `:merge(.group):not(:has(a:is(:active))):not(:has(input:is(:active))):not(:has(button:is(:active))):not(:has(select:is(:active))):not(:has(textarea:is(:active))):not(:has(*:is(.active))) &`,
            );
            addVariant(
                "peer-active-within",
                `:merge(.peer):has(a:is(:active), input:is(:active), button:is(:active), select:is(:active), textarea:is(:active), *:is(.active)) ~ &`,
            );
            addVariant(
                "peer-inactive-within",
                `:merge(.peer):not(:has(a:is(:active))):not(:has(input:is(:active))):not(:has(button:is(:active))):not(:has(select:is(:active))):not(:has(textarea:is(:active))):not(:has(*:is(.active))) ~ &`,
            );
        }),
        // hover-within variant
        plugin(function ({ addVariant }) {
            addVariant(
                "hover-within",
                `&:has(a:is(:hover), input:is(:hover), button:is(:hover), select:is(:hover), textarea:is(:hover), *:is(.hover))`,
            );
            addVariant(
                "unhover-within",
                `&:not(:has(a:is(:hover))):not(:has(input:is(:hover))):not(:has(button:is(:hover))):not(:has(select:is(:hover))):not(:has(textarea:is(:hover))):not(:has(*:is(.hover)))`,
            );
            addVariant(
                "group-hover-within",
                `:merge(.group):has(a:is(:hover), input:is(:hover), button:is(:hover), select:is(:hover), textarea:is(:hover), *:is(.hover)) &`,
            );
            addVariant(
                "group-unhover-within",
                `:merge(.group):not(:has(a:is(:hover))):not(:has(input:is(:hover))):not(:has(button:is(:hover))):not(:has(select:is(:hover))):not(:has(textarea:is(:hover))):not(:has(*:is(.hover))) &`,
            );
            addVariant(
                "peer-hover-within",
                `:merge(.peer):has(a:is(:hover), input:is(:hover), button:is(:hover), select:is(:hover), textarea:is(:hover), *:is(.hover)) ~ &`,
            );
            addVariant(
                "peer-unhover-within",
                `:merge(.peer):not(:has(a:is(:hover))):not(:has(input:is(:hover))):not(:has(button:is(:hover))):not(:has(select:is(:hover))):not(:has(textarea:is(:hover))):not(:has(*:is(.hover))) ~ &`,
            );
        }),
        // virtual-focused-within variant
        plugin(function ({ addVariant }) {
            addVariant("virtual-focused", `&:is(.virtual-focused)`);
            addVariant("virtual-focused-within", `&:has(*:is(.virtual-focused))`);
            addVariant("group-virtual-focused-within", `:merge(.group):has(*:is(.virtual-focused)) &`);
            addVariant("peer-virtual-focused-within", `:merge(.peer):has(*:is(.virtual-focused)) ~ &`);
        }),
        // screen
        plugin(function ({ addVariant }) {
            addVariant("screen", "@media screen");
        }),
        typography,
    ],
};
