import { requireAuth, requireInitialized, requireUnauth } from "@vueda/router/guards.js";
import { makeCRUDRoutes } from "@vueda/router/makeCrud.js";
import { setCrudComponents } from "@vueda/router/routerComponent.js";
import { getPascalCaseName } from "@vueda/utils/crudSupport.js";
import { createRouter, createWebHistory } from "vue-router";

/**
 * Create and return a new router instance.
 *
 * @param app {import('vue').App} - The Vue app instance.
 * @param pinia {import('pinia').Pinia} - The Pinia instance.
 * @returns {import('vue-router').Router} The router instance.
 */
export function getRouter(app, pinia) {
    const crudComponents = {
        list: async (argsObj) => {
            try {
                return (
                    await import(
                        `@/views/ViewList${getPascalCaseName(argsObj.app)}${getPascalCaseName(argsObj.model)}.vue`
                    )
                ).default;
            } catch {
                return (await import("@/components/DefaultViewList.vue")).default;
            }
        },
        create: async (argsObj) => {
            try {
                return (
                    await import(
                        `@/views/ViewCreate${getPascalCaseName(argsObj.app)}${getPascalCaseName(argsObj.model)}.vue`
                    )
                ).default;
            } catch {
                return (await import("@/components/DefaultViewCreate.vue")).default;
            }
        },
        update: async (argsObj) => {
            try {
                return (
                    await import(
                        `@/views/ViewUpdate${getPascalCaseName(argsObj.app)}${getPascalCaseName(argsObj.model)}.vue`
                    )
                ).default;
            } catch {
                return (await import("@/components/DefaultViewUpdate.vue")).default;
            }
        },
        read: async (argsObj) => {
            try {
                return (
                    await import(
                        `@/views/ViewRead${getPascalCaseName(argsObj.app)}${getPascalCaseName(argsObj.model)}.vue`
                    )
                ).default;
            } catch {
                return (await import("@vueda/views/ViewRead.vue")).default;
            }
        },
        destroy: async (argsObj) => {
            try {
                return (
                    await import(
                        `@/views/ViewDestroy${getPascalCaseName(argsObj.app)}${getPascalCaseName(argsObj.model)}.vue`
                    )
                ).default;
            } catch {
                return (await import("@/views/ViewDestroy.vue")).default;
            }
        },
    };
    setCrudComponents(crudComponents);

    const router = createRouter({
        history: createWebHistory(import.meta.env.BASE_URL),
        routes: [],
    });

    // add routes after so we can pass the router to router guards in makeCRUDRoutes
    const routes = [
        {
            path: "/theme",
            name: "theme",
            component: () => import("@/views/ViewTheme.vue"),
            beforeEnter: () => requireInitialized(router, pinia),
        },
        {
            path: "/",
            name: "landing",
            beforeEnter: () => requireUnauth({ name: "welcome" }, router),
            redirect: { name: "sign-in" },
        },
        {
            path: "/welcome",
            name: "welcome",
            component: () => import("@/views/ViewWelcome.vue"),
            beforeEnter: (to) => requireAuth({ name: "sign-in" }, to, router),
        },
        {
            path: "/sign-in",
            name: "sign-in",
            component: () => import("@/views/ViewSignIn.vue"),
            beforeEnter: () => requireUnauth({ name: "welcome" }, router),
        },
        {
            path: "/sign-out",
            name: "sign-out",
            component: () => import("@/views/ViewSignOut.vue"),
        },
        {
            path: "/forgot-password",
            name: "forgot-password",
            component: () => import("@/views/ViewForgotPassword.vue"),
            beforeEnter: () => requireUnauth({ name: "welcome" }, router),
        },
        ...makeCRUDRoutes({
            component: async () => (await import("@vueda/views/ViewActionRouter.vue")).default,
            authRedirect: { name: "sign-in" },
            groupsRedirect: { name: "welcome" },
            actionRedirect: { name: "not-found" },
            groups: [],
            vueApp: app,
            router,
            pinia,
        }),
        {
            path: "/:pathMatch(.*)*",
            name: "not-found",
            component: async () => (await import("@vueda/views/ViewNotFound.vue")).default,
            meta: {
                title: "Not Found",
                titles: {
                    view: "Not Found",
                },
            },
            beforeEnter: () => requireInitialized(router, pinia),
            props: (route) => {
                return {
                    ...(route.params || {}),
                    ...(route.query || {}),
                    title: route.meta.title,
                };
            },
        },
    ];

    for (const route of routes) {
        router.addRoute(route);
    }

    return router;
}
