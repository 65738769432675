<script setup>
import { FontAwesomeIcon, FontAwesomeLayers } from "@arrai-innovations/vue-fontawesome";

import { useIconMap } from "@/use/useIconMap.js";

defineOptions({
    name: "FontAwesomeIconDisplay",
});

const props = defineProps({
    /** @type {import("@arrai-innovations/fontawesome-svg-core").FontAwesomeIconDefinition} */
    icon: {
        type: [String, Array, Object],
        default: undefined,
    },
    /** @type {import("@arrai-innovations/fontawesome-svg-core").FontAwesomeIconDefinition[]} */
    multiIcons: {
        type: Array,
        default: undefined,
    },
    verb: {
        type: String,
        default: undefined,
    },
    state: {
        type: String,
        default: undefined,
    },
    fixedWidth: {
        type: Boolean,
        default: true,
    },
});
const iconMap = useIconMap(props);
</script>
<template>
    <template v-if="multiIcons?.length">
        <!-- Recursion -->
        <font-awesome-icon-display
            v-for="(icon, index) in multiIcons"
            :key="index"
            :icon="icon"
            :fixedWidth="fixedWidth"
        />
    </template>
    <template v-else-if="icon?.type === 'layered'">
        <font-awesome-layers :fixed-width="fixedWidth">
            <font-awesome-icon v-for="(layer, index) in icon.icons" :key="index" v-bind="layer" />
        </font-awesome-layers>
    </template>
    <template v-else-if="icon?.type === 'single'">
        <font-awesome-icon :fixed-width="fixedWidth" v-bind="iconMap" />
    </template>
    <template v-else-if="iconMap">
        <font-awesome-icon :icon="iconMap" :fixed-width="fixedWidth" />
    </template>
</template>
