<script setup>
import { storeDarkMode } from "@vueda/stores/storeDarkMode.js";
import { storeUser } from "@vueda/stores/storeUser.js";
import { containsHtml, sanitizeMessage } from "@vueda/utils/html.js";
import { useClipboard } from "@vueuse/core";
import throttle from "lodash-es/throttle.js";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import ScrollTop from "primevue/scrolltop";
import Toast from "primevue/toast";
import { onMounted, ref, toRef, watch } from "vue";

import TheNav from "@/TheNav.vue";

const clipboard = useClipboard();
const userStore = storeUser();
const darkModeStore = storeDarkMode();
const removeNoTransition = () => {
    document.documentElement.classList.remove("!no-transition", "[&_*]:!no-transition");
};
const throttledRemoveNoTransition = throttle(removeNoTransition, 150, {
    leading: false,
    trailing: true,
});
watch(toRef(darkModeStore, "isDark"), (isDark) => {
    document.documentElement.classList.add("!no-transition", "[&_*]:!no-transition");
    if (isDark) {
        document.documentElement.classList.add("dark");
    } else {
        document.documentElement.classList.remove("dark");
    }
    throttledRemoveNoTransition();
});
onMounted(() => {
    // userStore.init();
    // simulate user loading
    setTimeout(() => {
        userStore.init();
    }, 1000);
    document.documentElement.classList.add("!no-transition", "[&_*]:!no-transition");
    darkModeStore.init();
    if (darkModeStore.isDark) {
        document.documentElement.classList.add("dark");
    }
    throttledRemoveNoTransition();
});
const toastErrorDetails = ref("");
const toastErrorShow = ref(false);
const showToastErrorDetails = (details) => {
    toastErrorDetails.value = details;
    toastErrorShow.value = true;
};
</script>

<template>
    <div class="flex print:!text-black h-full w-full">
        <ScrollTop />
        <div class="flex flex-col shrink-0 lg:flex-row h-full w-full relative">
            <the-nav v-if="userStore.loggedIn" />
            <main
                class="flex flex-col relative w-full min-h-full justify-between py-1 px-2 gap-2 md:py-2 md:px-4 md:gap-4"
            >
                <div class="pb-20">
                    <router-view />
                </div>
            </main>
            <Toast position="bottom-right">
                <template #message="slotProps">
                    <div class="flex flex-col flex-1">
                        <div v-if="slotProps.message.summary" class="font-bold">
                            {{ slotProps.message.summary }}
                        </div>
                        <template v-if="slotProps.message.detail">
                            <template v-if="containsHtml(slotProps?.message?.detail ?? '')">
                                <p v-html="sanitizeMessage(slotProps?.message?.detail ?? '')" />
                            </template>
                            <template v-else>
                                {{ slotProps?.message?.detail ?? "" }}
                            </template>
                        </template>
                        <Button
                            v-if="slotProps.message.pre"
                            label="Show Details"
                            :severity="slotProps.message.severity"
                            @click="showToastErrorDetails(slotProps.message.pre)"
                        />
                    </div>
                </template>
            </Toast>
            <Dialog
                v-model:visible="toastErrorShow"
                :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
                header="Message Details"
                maximizable
                modal
                :style="{ width: '50vw' }"
            >
                <pre class="w-100"><code>{{ toastErrorDetails }}</code></pre>
                <template #footer>
                    <Button label="Copy to Clipboard" @click="clipboard.copy(toastErrorDetails)" />
                </template>
            </Dialog>
        </div>
    </div>
</template>

<style scoped></style>
