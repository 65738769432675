import { patchTheme } from "@vueda/use/useTheme.js";

export function setupVuedaTheme() {
    const pt = {};
    for (const componentName of ["FieldSetTabularInline", "FieldSetStackedInline"]) {
        pt[componentName] = {
            title: {
                class: "text-xl",
            },
            hr: {
                // their brand is green, need to uncouple some things from primary to christi
                class: {
                    "border-primary-300": false,
                    "dark:border-primary-600": false,
                    "border-christi-300": true,
                    "dark:border-christi-600": true,
                },
            },
        };
    }
    pt["PageTitle"] = {
        container: {
            class: "bg-zinc-100 dark:bg-zinc-800",
        },
        gradient: {
            class: "bg-gradient-to-b from-zinc-100 to-transparent dark:from-zinc-800 dark:to-transparent",
        },
        spacer: {
            class: {
                "border-primary-300": false,
                "dark:border-primary-600": false,
                "border-christi-300": true,
                "dark:border-christi-600": true,
            },
        },
        divider: {
            class: {
                "border-primary-300": false,
                "dark:border-primary-600": false,
                "border-christi-300": true,
                "dark:border-christi-600": true,
            },
        },
    };
    pt["ViewAction"] = {
        root: {
            class: ["flex flex-row gap-2"],
        },
    };
    pt["ActionForm"] = {
        listItem: {
            class: ["grow"],
        },
        themeOverride: {
            WidgetReadOnly: {
                linkItem: {
                    class: "!flex !justify-start",
                },
                textItem: {
                    class: "",
                },
            },
        },
    };
    pt["StickyBar"] = {
        inner: {
            class: {
                "bg-white dark:bg-black": false,
                "bg-zinc-100 dark:bg-zinc-800": true,
            },
        },
        gradient: {
            class: {
                "bg-gradient-to-b from-white to-transparent dark:from-black dark:to-transparent": false,
                "bg-gradient-to-b from-zinc-100 to-transparent dark:from-zinc-800 dark:to-transparent": true,
            },
        },
    };
    pt["FormModel"] = {
        field: {
            class: ({ inFieldSet }) => ({
                "rounded-md border 2xs:border-2 border-primary-200 dark:border-primary-800 p-1 2xs:p-2": !inFieldSet,
            }),
        },
    };
    pt["ObjectsGrid"] = {
        bodyRow: {
            class: "border-primary-300 dark:border-primary-700",
        },
    };
    patchTheme(pt);
}
